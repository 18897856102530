<template>
  <div class="navbar">
    <img src="@/assets/logo.png" alt="SmartMatch Insurance Solutions logo" />
    <div class="buttons" :class="{ open }">
      <a @click="open = false" href="#medicare">Medicare Advantage</a>
      <a @click="open = false" href="#medicare">Medicare Supplement</a>
      <a @click="open = false" href="#about-us"
        >Who is SmartMatch Insurance Solutions</a
      >
      <a href="tel: 8555029552"
        ><h3 class="mb-0 mobileNumber">
          <span class="orangeMobile"><strong>1-855-502-9552</strong></span> |
          <span class="purpleMobile"><strong>TTY: 711</strong></span>
        </h3></a
      >
    </div>
    <div class="navText floatRight text-right align-self-center">
      <h1>Call a licensed insurance agent | M - F, 8am - 5pm CST</h1>
      <div class="flex">
        <img
          src="@/assets/phone-orange.png"
          class="img-responsive ml-auto navImg pr-2"
          alt="phone icon"
        />
        <a href="tel: 8884117647"
          ><h3 class="mb-0"><strong>1-888-411-7647</strong></h3></a
        >
        <h3 class="mb-0 horzLine">
          | <span><strong>TTY: 711</strong></span>
        </h3>
      </div>
    </div>
    <i class="menu fas fa-bars" :class="{ open }" @click="open = !open"></i>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      open: false
    };
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 3;
  display: flex;
  background-color: white;
  min-height: 65px;
  position: sticky;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.75) 3px -3px 26px -4px;
  padding: 20px;
  .mobileNumber {
    display: none;
  }
  .navText {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 25px auto 0;
    padding-top: 20px;
    h1 {
      font-family: Arial;
      font-size: 14px !important;
      color: #6e6d6f;
      margin: 0;
      line-height: 2;
    }
    h3 {
      color: #ed5c2e;
      margin: 0;
    }
    .horzLine {
      color: #6e6d6f !important;
      padding-left: 10px;
      span {
        color: #45295c;
      }
    }
    .flex {
      display: flex;
      align-items: center;
    }
    a {
      padding: 0;
    }
    img {
      padding-right: 10px;
      margin-left: 0;
      height: auto;
    }
    @media (max-width: "1300px") {
      display: none;
    }
  }

  img {
    height: 53px;
    margin: auto 0 auto 25px;
    @media (max-width: "1300px") {
      margin: auto 0 auto 0px;
    }
  }

  .buttons {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 265px;
    width: fit-content;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background-color: white;
    transition: height 0.25s ease-in-out;
    padding-top: 20px;
    @media (max-width: "1300px") {
      padding-top: 0px;
    }
  }

  .menu {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .navbar {
    .mobileNumber {
      display: block;
    }
    h3 {
      margin: 0;
      text-align: left;
      color: #6e6d6f;
    }
    .orangeMobile {
      color: #ed5c2e;
    }

    .purpleMobile {
      color: #45295c;
    }
    // img {
    //   height: 80px;
    //   margin: auto 0 auto 10px;
    // }

    .buttons {
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      height: 0;
      flex-direction: column;

      a {
        width: 100%;
        padding-top: 4px;
      }
    }

    .buttons.open {
      height: 175px;
    }

    .menu {
      display: block;
      position: absolute;
      top: 40%;
      bottom: 40%;
      height: 20%;
      font-size: 24px;
      right: 30px;
      margin: auto 0;
      line-height: 100%;
      transition: color 0.15s ease-in-out;
    }

    .menu.open {
      color: #ed5c2e;
    }
  }
}

a {
  padding: 14px;
  text-decoration: none;
  color: #727272;
  font-family: "Lato", sans-serif;
  line-height: 32px;
}
</style>
