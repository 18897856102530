<template>
  <i class="icon-circle fas"></i>
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.min.css";

export default {
  name: "IconCircle"
};
</script>

<style lang="scss" scoped>
.icon-circle {
  border: 1px solid #d9481a;
  background: linear-gradient(to bottom, #ff7a4c 0%, #ed5c2e 100%);
  width: 70px;
  height: 70px;
  border-radius: 35px;
  color: white;
  line-height: 70px;
  font-size: 40px;
  text-align: center;
}
</style>
