<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card"
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 5px;
  border-color: rgba(69, 41, 92, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 10px 10px 2px 2px;
  margin: 10px 15px 10px 15px;
  background-color: rgba(255, 255, 255, 1);
  flex-direction: column;
  flex-basis: 350px;
  max-width: 100%;
  min-width: 250px;
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.card {
  position: relative;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 5px;
  border-color: rgba(69, 41, 92, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 10px 10px 2px 2px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 1);
  flex-direction: column;
  flex-basis: 350px;
  max-width: 100%;
  min-width: 250px;
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
</style>
