<template>
  <div class="footer">
    <div class="company-info">
      <img src="@/assets/smis-white.png" alt="SmartMatch Insurance Solutions logo" />
      <b>Corporate Office</b>
      <br />120 W. 12th Street,
      <br />Suite 1700
      <br />Kansas City, MO 64105
      <br />
      <br />
      <a href="tel: 8884117647">1-888-411-7647 | TTY: 711</a>
      <br />Monday-Friday, 8am-5pm CST
    </div>
    <div class="copyright">
      Copyright © 2020 SmartMatch Insurance Solutions. All rights reserved.
      <div class="link" @click="$refs.privacy.activate()">Privacy Policy</div>&nbsp;|
      <div class="link" @click="$refs.terms.activate()">Terms &amp; Conditions</div>
      <br />
      <br />We believe the sources we use to provide information for this
      website are reliable. However, we do not guarantee or warrant the accuracy
      of this information. Smartmatchinsurancesolutions.com provides the
      material on this website for informational purposes only. Medicare has
      neither endorsed nor reviewed the information provided on this website.
      <br />
      <br />The contents of this website are subject to change without notice.
      If you would like to find more information about the Government Medicare
      program, please visit the Official US Government Site for People with
      Medicare located at
      <a
        class="link"
        target="blank"
        href="https://www.medicare.gov"
      >www.medicare.gov</a>. Neither SmartMatch Insurance Solutions nor its agents are connected
      with or endorsed by the U.S. government, the federal Medicare program or
      any State agency.
    </div>
    <Modal ref="privacy">
      <h2>Privacy Policy</h2>
      <div>
        We are committed to protecting your privacy. SmartMatch Insurance
        Solutions is an online source for people seeking to learn more about
        their insurance options. It is important for you to know and understand
        how our website(s) may collect and use your information and the steps we
        take to protect your privacy. Please read this carefully and note that
        this Privacy Policy only applies to this website.
      </div>
      <h3>Consent</h3>
      <div>
        By using or accessing this website, you are giving SmartMatch Insurance
        Solutions consent to access and use aggregate-level information about
        your website visit and agree to the terms of use herein.
      </div>
      <h3>Information Collection</h3>
      <div>
        This policy helps to explain how, why and when we collect information
        about your website experience on smartmatchinsurancesolutions.com.
        Cookies may be sent to visitors of this website. “Cookies” are text-only
        pieces of information that a website transfers to an individual’s hard
        drive or other web site-browsing equipment for record-keeping purposes.
        Cookies allow the website to remember important information that will
        make your use of the site more convenient. A cookie will typically
        contain the name of the domain from which the cookie has come, the
        “lifetime” of the cookie, and a randomly generated, unique number or
        other value. Like most websites, we use cookies to analyze user activity
        in order to improve the website and your overall experience. For
        example, using cookies, we can look at aggregate patterns, such as the
        average number of visits a page per day. We can use such analysis to
        gain insights about how to improve the functionality and user experience
        of the website.
      </div>
      <h3>Changes to This Policy</h3>
      <div>
        SmartMatch Insurance Solutions reserves the right to modify this privacy
        statement at any time, so please review it frequently. If we make
        material changes to this policy, we will notify you here. An updated
        copy of this Privacy Policy will be clearly posted and accessible on
        this website at all times.
      </div>
    </Modal>
    <Modal ref="terms">
      <h2>Terms &amp; Conditions</h2>
      <h3>Terms of Use Agreement</h3>
      <div>
        This Terms and Conditions of Use Agreement (“Terms of Use”) constitutes
        a binding agreement between you and SmartMatch Insurance Solutions.
        Please read carefully through all sections of these Terms of Use. By
        accessing this website you agree to be bound by these Terms of Use.
        Please be advised that any information you submit to SmartMatch
        Insurance Solutions, through a customer service representative, on our
        site, via emails, or otherwise, becomes the property of SmartMatch
        Insurance Solutions. If you do not acknowledge and agree to these Terms
        of Use, then do not provide your information. Your access to, and use of
        smartmatchinsurancesolutions.com is subject to these Terms of Use and
        all applicable laws. These Terms of Use may be changed by us from time
        to time without notice to you, and the latest version will be posted on
        this Site.
      </div>
      <h3>About This Site</h3>
      <div>
        Smartmatchinsurancesolutions.com (hereinafter “the Site” or “this Site”)
        is an online resource for people seeking to learn more about their
        insurance options. SmartMatch Insurance Solutions does not endorse any
        particular insurance plan, provider, or agent. Any information provided
        about any particular insurance plan, provider, or agent shall not be
        construed as an endorsement by SmartMatch Insurance Solutions.
      </div>
      <h3>Copyright and Proprietary Rights Information</h3>
      <div>
        This website is owned and maintained by SmartMatch Insurance Solutions.
        No content or images may be downloaded, or reproduced without our prior
        and explicit consent.
      </div>
      <div>
        While every effort is made to ensure the accuracy of the content of
        smartmatchinsurancesolutions.com, we cannot be held accountable for
        typographical errors, errors of fact, or any other error or
        inconsistency found on this website. We hereby disclaim all liability
        from any damages resulting from the use of this website, or any services
        provided on any SmartMatch Insurance Solutions website.
      </div>
      <h3>Links to Third Party Sites</h3>
      <div>
        This Site may contain links to sites that are controlled by third
        parties. These linked sites are not under the control of SmartMatch
        Insurance Solutions, and SmartMatch Insurance Solutions is not
        responsible for the contents of any linked site or any link contained in
        a linked site.
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "Footer",
  components: {
    Modal
  }
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 30px 30px;
  background-color: rgb(69, 41, 92);
  padding-top: 40px;
  color: white;

  .company-info {
    margin: 0 30px 25px 0;

    img {
      display: block;
      width: 180px;
      margin-bottom: 10px;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .copyright {
    flex: 1;
    margin: 4px 0 0;

    .link {
      display: inline;
      color: rgb(255, 119, 101);
      text-decoration: none;
      cursor: pointer;
    }

    .link:hover {
      text-decoration: underline;
    }
  }
}
</style>
