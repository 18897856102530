<template>
  <div class="home">
    <nav-bar></nav-bar>
    <div
      id="hero"
      :style="{
        backgroundImage: 'url(' + require('@/assets/seniorcouch.jpg') + ')'
      }"
    >
      <h1>
        <b>SmartMatch Insurance Solutions</b> helps you find the right Medicare
        coverage with our team of dedicated, unbiased advisors
      </h1>
      <div class="mask"></div>
    </div>
    <div class="section">
      <h2>What makes SmartMatch Insurance Solutions different?</h2>
      <card>
        <div class="card-content">
          <icon-circle class="fa-paste"></icon-circle>
          <h3>Multiple options</h3>
          <div>
            Our advisors compare policy rates across multiple carriers to find
            the right policy to suit your needs. Whether that means enrolling
            for the first time or finding a lower rate, we help you determine
            which Medicare partner makes sense for you.
          </div>
        </div>
      </card>
      <card>
        <div class="card-content">
          <icon-circle class="fi-torsos-all-female"></icon-circle>
          <h3>Personalized assistance</h3>
          <div>
            We ask you the right questions to determine which plans offer the
            most suitable coverage for your health and lifestyle. No matter how
            much coverage you need, there's a plan and policy that will work for
            you.
          </div>
        </div>
      </card>
      <card>
        <div class="card-content">
          <icon-circle class="fa-leaf"></icon-circle>
          <h3>Unbiased solutions</h3>
          <div>
            Medicare coverage isn’t one-size-fits-all. We help you determine
            which Medicare path works for you—Medicare Advantage or Medicare
            Supplement—and find any additional coverage you may need, such as
            prescription medication.
          </div>
        </div>
      </card>
    </div>
    <div id="medicare" class="section white">
      <h2>Which coverage is right for you?</h2>
      <card>
        <img
          class="banner-image"
          alt="A man holding pills"
          :src="require('@/assets/medicare-pills.jpg')"
        />
        <div class="card-content left">
          <h3>Medicare Advantage</h3>
          <div>
            Our advisors compare policy rates across multiple carriers to find
            the right policy to suit your needs. Whether that means enrolling
            for the first time or finding a lower rate, we help you determine
            which Medicare partner makes sense for you.
          </div>
        </div>
      </card>
      <Card>
        <img
          class="banner-image"
          alt="A doctor doing paperwork"
          :src="require('@/assets/medicare-doctor.jpg')"
        />
        <div class="card-content left">
          <h3>Medicare Supplement</h3>
          <div>
            We ask you the right questions to determine which plans offer the
            most suitable coverage for your health and lifestyle. No matter how
            much coverage you need, there's a plan and policy that will work for
            you.
          </div>
        </div>
      </Card>
    </div>
    <div id="about-us" class="section">
      <h2>Who is SmartMatch Insurance Solutions?</h2>
      <div id="agent-text">
        <div>
          We know enrolling in Medicare can be a confusing, frustrating, and
          painstaking process—but it doesn't have to be. SmartMatch Insurance
          Solutions is an ally for your Medicare needs.
          <br />
          <br />SmartMatch Insurance Solutions considers multiple coverage
          options to find a Medicare plan for your individual healthcare needs.
          We're a team of licensed sales agents who ask you personalized
          questions to determine the level of coverage you may need—and coverage
          you don't.
          <br />
          <br />Our sales agents are unbiased—which means the only option that
          works for us is the option that works for you. We compare plans,
          policies, carriers, networks, and rates to help you understand the
          combination of coverage to help you live your healthiest life.
        </div>
        <img
          id="agent-image"
          src="@/assets/spring-agent.jpg"
          alt="A SmartMatch Insurance Solutions sales agent"
        />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Card from "@/components/Card.vue";
import IconCircle from "@/components/IconCircle.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "home",
  components: {
    NavBar,
    Card,
    IconCircle,
    Footer
  },
  metaInfo: {
    title: "SmartMatch Insurance Solutions | Unbiased Solutions",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Medicare coverage isn’t one-size-fits-all. We help you determine which Medicare path works for you—Medicare Advantage or Medicare Supplement. Plus, any additional coverage for things like prescription medication and more."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "SmartMatch Insurance Solutions | Unbiased Solutions"
      },
      {
        vmid: "twitter:title",
        property: "twitter:title",
        content: "SmartMatch Insurance Solutions | Unbiased Solutions"
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Medicare coverage isn’t one-size-fits-all. We help you determine which Medicare path works for you—Medicare Advantage or Medicare Supplement. Plus, any additional coverage for things like prescription medication and more."
      },
      {
        vmid: "twitter:description",
        property: "twitter:description",
        content:
          "Medicare coverage isn’t one-size-fits-all. We help you determine which Medicare path works for you—Medicare Advantage or Medicare Supplement. Plus, any additional coverage for things like prescription medication and more."
      }
    ]
  }
};
</script>

<style lang="scss" scoped>
#hero {
  position: relative;
  width: 100%;
  height: 620px;
  background-position: center top;
  background-size: cover;
  @media (max-width: "991px") {
    background-position: unset;
  }
  .mask {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 62%,
      rgba(255, 255, 255, 0.7805497198879552) 72%
    );
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    @media (max-width: "991px") {
      background: #fff;
      opacity: 0.7805497198879552;
    }
  }
}

#hero h1 {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 2.5%;
  font-weight: 400;
  text-shadow: 1px 1px 2px #ffffff;
  max-width: 600px;
  width: 95%;
  height: 250px;
  z-index: 2;

  @media (max-width: "991px") {
    margin: auto;
    left: 0;
  }
}

#home b {
  font-weight: 900;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 30px 40px;
}

.section h2 {
  width: 100%;
}

.section.white {
  background-color: white;
}

.banner-image {
  height: 300px;
  margin-bottom: -30px;
  object-fit: cover;
}
.card-content {
  margin: 40px 30px;
}

.banner-image {
  width: 100%;
  height: 300px;
  background-size: cover;
  margin-bottom: -30px;
}

.left {
  text-align: left;

  h3 {
    margin-left: 0;
    text-align: left;
  }
}

#agent-text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#agent-text div {
  margin: 0 25px;
  max-width: 50%;
  min-width: 300px;
}

#agent-image {
  width: 450px;
  max-width: 90%;
  margin: 15px 75px;
}
</style>
